<template>
  <v-card flat>
    <v-card-title>
      <menu-title title="playground/testing2"></menu-title>
    </v-card-title>

    <v-card-text>
      <span>Hey, you found the admin playground. Expect nothing but half-baked experimental features here. Have fun!</span>
    </v-card-text>

    <v-card-actions>
      <v-btn
        outlined
        color="#b31616"
        class="ml-2"
        @click="debug"
      >Do nothing</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import menuTitle from "../../components/helpers/menu_title.vue";
export default {
  name: "Testing2",
  components: {
    menuTitle,
  },
  methods: {
    debug: function () {
      console.log("Nothing happened... Who'd've guessed?");
    },
  },
};
</script>

<style>
</style>